<template>
  <div>
    <XyeaFormEdit
      :icon="Icons.room"
      url="rooms/"
      entityName="Room"
      :form="form"
      :id="form.roomId"
      @close="$emit('close')"
      @add="refresh"
      @update="refresh"
      :disabled="readonly"
      :dialog="true"
      :loadOnOpen="true"
      @mapServerData="mapServerData"
    >
      <b-field v-if="multiple" label="Room Nos." horizontal>
        <div class="level-left">
          <p class="unitLabel">From</p>
          <b-numberinput v-model="form.fromRoom" :controls="false" :min="1" :max="100" required />
          <p class="unitLabel">To</p>
          <b-numberinput v-model="form.toRoom" :controls="false" :min="1" :max="100" required />
        </div>
      </b-field>
      <NameField v-else label="Room Name" v-model="form.name" required :disabled="readonly" v-focus />

      <SelectLocation v-model="form.locationId" required :disabled="readonly" />
      <b-field label="Status" horizontal>
        <EnumDropDown v-model="form.status" :data="bedEnum.RoomStatusSelectLookup" required :disabled="readonly" />
      </b-field>
      <b-field label="Ensuite" horizontal>
        <YesNoPicker v-model="form.ensuite" name="ensuite" required :disabled="readonly" />
      </b-field>
      <b-field label="Dimensions" horizontal>
        <div class="levelLeftMobile">
          <b-numberinput v-model="form.dimensionX" :controls="false" :disabled="readonly" min="0" max="200" :step="0.1" />
          <p class="control unitLabel">m</p>
          <b-numberinput v-model="form.dimensionY" :controls="false" :disabled="readonly" min="0" max="200" :step="0.1" />
          <p class="control unitLabel">m</p>
        </div>
      </b-field>
      <NotesIconField notesRef="notes" v-model="form.notes" :readonly="readonly" />
      <b-field label="Type" horizontal>
        <EnumDropDown v-model="form.type" :data="bedEnum.RoomTypeSelectLookup" required :disabled="readonly" />
      </b-field>

      <CollapseCard
        v-for="bed in form.beds"
        :key="bed.bedId"
        :title="'Bed: ' + form.name + bed.name"
        class="primaryCard"
        :open.sync="bed.bedInfoOpen"
      >
        <div class="columns">
          <div class="column">
            <NameField label="Name" v-model="bed.name" required :disabled="readonly" class="label-medium" />
            <b-field label="Service" horizontal class="label-medium">
              <EnumDropDown v-model="bed.serviceType" :data="serviceType" required :disabled="readonly" />
            </b-field>
            <b-field label="Status" horizontal class="label-medium">
              <EnumDropDown v-model="bed.status" :data="bedEnum.BedStatusSelectLookup" required :disabled="readonly" />
            </b-field>
          </div>
          <div class="column">
            <NameField label="Reg No." v-model="bed.regName" :disabled="readonly" class="label-medium" />
            <MoneyField label="Rate" v-model="bed.rate" required :disabled="readonly" class="label-medium" />
          </div>
        </div>
      </CollapseCard>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import NameField from "@/components/NameField";
import MoneyField from "@/components/MoneyField";
import NotesIconField from "@/components/NotesIconField";
import SelectLocation from "@/components/SelectLocation.vue";
import YesNoPicker from "@/components/YesNo.vue";
import EnumDropDown from "@/components/EnumDropDown.vue";
import CollapseCard from "@/components/CollapseCard.vue";

import BedsEnum from "@/enums/beds";
import ServiceType from "@/enums/serviceType";
import PermissionsEnum from "@/enums/permissions";
import IconsMixin from "@/mixins/iconsMixin.js";
import CheckDirty from "@/mixins/checkDirty.js";

export default {
  mixins: [IconsMixin, CheckDirty],
  components: { XyeaFormEdit, NameField, MoneyField, NotesIconField, SelectLocation, YesNoPicker, EnumDropDown, CollapseCard },
  props: {
    room: { type: Object },
    multiple: { type: Boolean }
  },
  data() {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      bedEnum: BedsEnum,
      serviceType: ServiceType.SelectLookup,
      newBedId: 0
    };
  },
  computed: {
    readonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.RoomAdmin);
    }
  },
  watch: {
    "form.type": {
      immediate: true,
      handler: "ensureBedsMatchType"
    }
  },
  methods: {
    ensureBedsMatchType() {
      if (this.form.type > this.form.beds?.length) {
        this.addBed();
        this.ensureBedsMatchType();
      } else if (this.form.type < this.form.beds?.length) {
        this.form.beds.pop();
      }
    },
    addBed() {
      if (!this.form.beds) this.form.beds = [];
      this.form.beds.push({
        bedId: this.newBedId--,
        name: "",
        regName: null,
        rate: null,
        serviceType: null,
        status: null,
        assestNum: null,
        bedInfoOpen: true
      });
    },
    getClearFormObject() {
      return {
        fromRoom: null,
        toRoom: null,
        multiple: this.multiple,
        roomId: 0,
        name: "",
        organisationId: this.$store.getters.orgId,
        locationId: null,
        ensuite: null,
        dimensionX: null,
        dimensionY: null,
        status: BedsEnum.RoomStatusEnum.Normal,
        type: BedsEnum.RoomTypeEnum.Single,
        notes: null,
        beds: []
      };
    },
    refresh() {
      this.$emit("refresh");
    }
  },
  mounted() {
    if (this.room?.roomId) {
      this.form = { ...this.room };
    }
  }
};
</script>
