<template>
  <section class="section is-main-section">
    <div class="box">
      <div class="columns">
        <div class="column">
          <SelectLocation v-model="search.locationId" />
        </div>
        <div class="column">
          <b-field horizontal label="Service">
            <b-field>
              <EnumDropDown v-model="search.serviceType" :data="serviceType.SelectLookup" :required="false" :includeAll="true" />
            </b-field>
          </b-field>
        </div>
        <div class="column">
          <b-field horizontal label="Room / Bed" class="label-long">
            <b-input v-model="search.name" />
          </b-field>
        </div>

        <div class="column">
          <b-field horizontal label="Bed Status" class="label-long">
            <b-field>
              <EnumDropDown
                v-model="search.bedStatus"
                :data="bedsEnum.BedStatusSelectLookup"
                :required="false"
                :includeAll="true"
                :allValue="-1"
              />
              <ClearSearch @clearSearch="clearSearch" />
            </b-field>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <b-field horizontal label="From">
            <b-datepicker v-model="search.fromDate" icon="calendar-today" />
          </b-field>
        </div>
        <div class="column is-3">
          <b-field horizontal label="To">
            <b-datepicker v-model="search.toDate" icon="calendar-today" />
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <ContextTableCard
          title="Room / Bed Management"
          :icon="Icons.bed"
          :header-icons="headerIcons"
          :tableData="tableData"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selected"
          @headerIconClicked="headerIconClicked"
          @contextDefaultClicked="editRoom"
          :menuOptions="contextOptions"
          @menu-clicked="contextMenuClicked"
          :isLoading="isLoading"
        >
          <b-table-column label="Location" field="location" sortable v-slot="props">
            {{ locationNameLookup(props.row.locationId, props.row.locationName, true) }}
          </b-table-column>
          <b-table-column label="Room / Bed" field="name" sortable v-slot="props">
            {{ props.row.fullName }}
          </b-table-column>
          <b-table-column label="Room Type" field="type" sortable v-slot="props">
            {{ bedsEnum.RoomTypeLookup[props.row.roomType] }}
          </b-table-column>
          <b-table-column label="Ensuite" field="ensuite" sortable v-slot="props">
            <b-checkbox v-model="props.row.ensuite" disabled />
          </b-table-column>
          <b-table-column label="Service" field="serviceType" sortable v-slot="props">
            {{ serviceType.Lookup[props.row.serviceType] }}
          </b-table-column>
          <b-table-column label="Bed Status" field="bedStatus" sortable v-slot="props">
            {{ bedsEnum.BedStatusLookup[props.row.trueBedStatus] }}
          </b-table-column>
          <b-table-column custom-key="actions" v-slot="props">
            <div class="buttons is-right">
              <ClickIcon title="Edit Room / Bed" @click="editRoom(props.row.roomId)" icon="edit" />

              <DeleteObject
                v-if="isAdmin"
                entity="Room and its Beds"
                :deleteName="props.row.roomName"
                :deleteId="props.row.roomId"
                url="rooms"
                @deleted="debounceRefreshData"
              />
            </div>
          </b-table-column>
        </ContextTableCard>
      </div>
    </div>
  </section>
</template>

<script>
import SelectLocation from "@/components/SelectLocation.vue";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import ClearSearch from "@/components/ClearSearch.vue";
import RoomDialog from "@/components/dialogs/RoomDialog.vue";
import EnumDropDown from "@/components/EnumDropDown";
import ClickIcon from "@/components/ClickIcon.vue";
import DeleteObject from "@/components/DeleteObject.vue";

import IconsMixin from "@/mixins/iconsMixin.js";
import BedsEnum from "@/enums/beds";
import ServiceType from "@/enums/serviceType";
import dates from "@/common/dates";

export default {
  components: { SelectLocation, ContextTableCard, ClearSearch, EnumDropDown, ClickIcon, DeleteObject },
  mixins: [ContextTableMixin, IconsMixin],
  data() {
    const url = "rooms";
    const savedSearch = this.$store.getters["search/savedSearch"](url);

    return {
      url: url,
      entity: "Rooms",
      headerIcons: [IconsMixin.headerIconAddMultiple("Rooms/Beds"), IconsMixin.headerIconAdd("Room/Bed"), IconsMixin.headerIconRefresh()],
      multipleRows: [],
      search: {
        locationId: savedSearch.locationId,
        serviceType: savedSearch.serviceType ?? ServiceType.Enum.All,
        bedStatus: savedSearch.bedStatus ?? BedsEnum.BedStatusEnum.All,
        name: savedSearch.name,
        page: savedSearch.page ?? 1,
        sortField: savedSearch.sortField ?? "name",
        sortOrder: savedSearch.sortOrder ?? "asc",
        fromDate: dates.today(),
        toDate: null
      },
      bedsEnum: BedsEnum,
      serviceType: ServiceType
    };
  },
  computed: {
    contextOptions() {
      var options = [{ text: "Edit", event: "edit-room" }];
      if (this.multipleRows.length) {
        options.push({ text: "Send Multiple (" + this.multipleRows.length + ") Password Reset Emails", event: "password-reset-multiple" });
      }
      return options;
    }
  },
  methods: {
    async headerIconClicked(iconName) {
      switch (iconName) {
        case "add":
          this.showRoom();
          break;
        case "multiple":
          this.showRoom(null, true);
          break;
      }
    },
    contextMenuClicked(event) {
      switch (event) {
        case "edit-room":
          this.editRoom();
          break;
      }
    },
    showRoom(room, multiple) {
      this.$buefy.modal.open({
        parent: this,
        component: RoomDialog,
        hasModalCard: true,
        props: { room: room, multiple: multiple },
        events: { refresh: this.debounceRefreshData }
      });
    },
    editRoom(id) {
      id = id || this.selected.roomId;
      if (id) this.showRoom({ roomId: id });
    },
    clearSearch() {
      this.search.locationId = null;
      this.search.name = null;
      this.search.bedStatus = BedsEnum.BedStatusEnum.All;
      this.search.serviceType = ServiceType.Enum.All;
      this.search.fromDate = dates.today();
      this.search.toDate = null;
    }
  }
};
</script>
