<template>
  <b-field :label="label" horizontal>
    <b-field>
      <p class="control unitLabel">€</p>
      <b-numberinput
        :value="value"
        @input="v => $emit('input', v)"
        :controls="false"
        :disabled="disabled"
        min="0"
        :max="max"
        :step="0.01"
      />
    </b-field>
  </b-field>
</template>

<script>
export default {
  props: {
    value: { type: Number },
    placeholder: { type: String },
    disabled: { type: Boolean },
    required: { type: Boolean },
    label: { type: String },
    max: { type: Number }
  }
};
</script>
